import moment from 'moment';

export default class Period {
    constructor(year, period) {
        this.year = year;
        this.period = period;
    }

    static fromMoment(m) {
        if (m === null) return null;
        return new this(m.year(), m.month() + 1);
    }

    toMoment() {
        return moment(`${this.year}-${this.period}-01`, 'YYYY-MM-DD');
    }

    equals(other) {
        return (
            other instanceof Period &&
            other.year === this.year &&
            other.period === this.period
        );
    }

    isBefore(other) {
        return (
            this.year < other.year ||
            (this.year == other.year && this.period < other.period)
        );
    }

    isBeforeOrEqual(other) {
        return this.isBefore(other) || this.equals(other);
    }

    isAfter(other) {
        return !this.isBefore(other) && !this.equals(other);
    }

    isAfterOrEqual(other) {
        return this.isAfter(other) || this.equals(other);
    }
}
