import Vue from 'vue';
import Vuex from 'vuex';
import errorHandling from './errorHandling';
import snackbar from './snackbar';
import frontendVersion from './frontendVersion';
import m from '../apiEndPoints';
import kiosk from './kiosk';
import { getCurrentRefresh } from '../asyncComputed';
import shiftRefresh from './shiftRefresh';

Vue.use(Vuex);

export { store };

const willNeverResolve = new Promise(() => {});

function storeModule(endpoint, { skipFirmCheck } = {}) {
    const updateObservers = new Map();

    const observeUpdates = predicate => {
        const refresh = getCurrentRefresh();

        const observer = update => {
            if (!predicate(update)) {
                return;
            }

            refresh();
        };

        if (!updateObservers.has(refresh)) {
            refresh.onCleanup(() => {
                updateObservers.delete(refresh);
            });
        }

        updateObservers.set(refresh, observer);

        if (updateObservers.size > 1000) {
            console.warn(
                `Over 1000 (${updateObservers.size}) update observers registered on a single store module`
            );
        }
    };

    return {
        namespaced: true,
        state: () => ({}),
        mutations: {
            update(state, updateOrUpdates = [{}]) {
                const updates =
                    updateOrUpdates instanceof Array
                        ? updateOrUpdates
                        : [updateOrUpdates];
                for (const update of updates) {
                    for (const observer of updateObservers.values()) {
                        observer(update);
                    }
                }
            },
        },
        getters: {
            get(state, getters, rootState) {
                state.version;
                if (!rootState.firm?.id && !skipFirmCheck) {
                    return () => willNeverResolve;
                }
                return requestParameters => {
                    observeUpdates(updateParameters => {
                        for (const [updateKey, updateValue] of Object.entries(
                            updateParameters
                        )) {
                            if (
                                updateValue !== requestParameters[updateKey] &&
                                JSON.stringify(updateValue) !==
                                    JSON.stringify(requestParameters[updateKey])
                            ) {
                                return false;
                            }
                        }
                        return true;
                    });

                    return endpoint(requestParameters);
                };
            },
        },
    };
}

function storeModules(modules) {
    return modules.reduce(
        (accumulator, [storeName, endpoint, opt]) => ({
            ...accumulator,
            [storeName]: storeModule(endpoint, opt),
        }),
        {}
    );
}

const store = new Vuex.Store({
    modules: {
        ...storeModules([
            // Employee endpoints
            [
                'employeeHolidayRequests',
                m.$employeeHolidayRequests,
                { skipFirmCheck: true },
            ],
            [
                'employeeWabOffers',
                m.$employeeWabOffers,
                { skipFirmCheck: true },
            ],
            ['employeeMe', m.$employeeMe, { skipFirmCheck: true }],
            [
                'employeeFirmDocuments',
                m.$employeeFirmDocuments,
                { skipFirmCheck: true },
            ],
            [
                'employeeHasActiveBreak',
                m.$employeeHasActiveBreak,
                { skipFirmCheck: true },
            ],
            ['actualShifts', m.$getActualShifts, { skipFirmCheck: true }], // other enpoints
            ['attendances', m.$getAttendances],
            ['holidaysInPeriod', m.$getHolidaysInPeriod],
            ['workedHours', m.$getWorkedHours],
            ['costFactor', m.$getEmployeeCostFactors],
            ['endOfDays', m.$getEndOfDays],
            ['endOfDaysComments', m.$getEndOfDaysComments],
            ['endOfDay', m.$getEndOfDay],
            ['scheduleCost', m.$getScheduleCost],
            ['contractTemplates', m.$getContractTemplates],
            [
                'paymentMethods',
                m.$getPaymentMethodsActual,
                { skipFirmCheck: true },
            ],
            ['prePlannedSchedules', m.$getPrePlannedSchedules],
            ['workRelation', m.$getWorkRelations],
            ['wageTax', m.$getWageTaxes],
            ['hours', m.$getHours],
            ['hoursBalance', m.$getHoursBalance],
            ['contracts', m.$getContracts],
            ['certificatesGraphql', m.$getCertificates],
            ['wages', m.$getWages],
            ['totalRevenue', m.$getTotalRevenue],
            ['totalPersonnelCost', m.$getTotalPersonnelCost],
            ['totalPersonnelHours', m.$getTotalPersonnelHours],
            [
                'totalPercentagePersonnelCost',
                m.$getTotalPercentagePersonnelCost,
            ],
            ['totalRevenuePerCategory', m.$getTotalRevenuePerCategory],
            ['totalPersonnelProductivity', m.$getTotalPersonnelProductivity],
            ['totalCostAndHoursPerStation', m.$getTotalCostAndHoursPerStation],
            [
                'totalCostAndHoursPerEmployee',
                m.$getTotalCostAndHoursPerEmployee,
            ],
            [
                'totalPaymentMethodCountDifference',
                m.$getTotalPaymentMethodCountDifference,
            ],
            ['rights', m.$getRights],
            ['right', m.$getRight],
            ['vaultMutations', m.$getVaultMutations],
            [
                'getFirmWorkReadyCheckIsDisabled',
                m.$getFirmWorkReadyCheckIsDisabled,
            ],
            ['availabilityRequests', m.$getAvailabilityRequests],
            [
                'employeeAvailabilityRequests',
                m.$getEmployeeAvailabilityRequests,
                { skipFirmCheck: true },
            ],
            ['availabilities', m.$getAvailabilities],
            ['attachmentsOfEarnings', m.$getAttachmentsOfEarnings],
            ['holidayRequests', m.$getHolidayRequests],
            ['holidayOverviews', m.$getHolidayOverviews],
            ['salaryScales', m.$getSalaryScales],
            ['salaries', m.$getSalaries],
            ['ageTables', m.$getAgeTables],
            ['functions', m.$getFunctions],
            ['costCenters', m.$getCostCenters],
            ['getFirm', m.$getFirm],
            ['attendancesGraphql', m.$getAttendancesGraphql],
            ['expectedRevenues', m.$getExpectedRevenues],
            ['prePlannedShifts', m.$getPrePlannedShifts],
            ['shiftTypes', m.$getShiftTypes],
            ['cameras', m.$getCameras],
            ['pools', m.$getPools],
            ['employees', m.$getEmployees],
            ['firmsGraphql', m.$getFirmsGraphql],
            ['illness', m.$getIllnesses],
            ['users', m.$getUsers],
            ['user', m.$getUser],
            ['signFlowTemplates', m.$getSignFlowTemplates],
            ['signerTypes', m.$getSignerTypes],
            ['signer', m.$publicSigner, { skipFirmCheck: true }],
            ['signFlows', m.$getSignFlows],
            ['wageScales', m.$getWageScales],
            ['wageFunctions', m.$getWageFunctions],
            ['documents', m.$getDocuments],
            ['firmDocuments', m.$getFirmDocuments],
            ['schedule', m.$getScheduleGraphql],
            ['todos', m.$getTodos],
            ['salaryHouse', m.$getSalaryHouse],
            ['periodHoursOverview', m.$getPeriodHoursOverview],
            ['certificateCoverage', m.$getCertificateCoverage],
            ['runRequests', m.$getRunRequests],
            ['currentPeriod', m.$getCurrentPeriod],
            ['wageComponents', m.$getWageComponents],
            ['shifts', m.$getShifts],
            ['tip', m.$getTip],
            ['journalItems', m.$getJournal],
            ['shiftCount', m.$getShiftCount],
            ['scheduledHours', m.$getScheduledHours],
            ['shiftCostForDay', m.$getShiftCostForDay],
            ['shiftsForDay', m.$getShiftsForDay],
            ['actualCost', m.$getActualCost],
            ['conflicts', m.$getConflicts],
            ['employeeDocuments', m.$getEmployeeDocuments],
            [
                'employeeDocumentRequests',
                m.$employeeDocumentRequests,
                { skipFirmCheck: true },
            ],
            ['producedHoursSources', m.$getProducedHoursSources],
        ]),
        errorHandling,
        snackbar,
        frontendVersion,
        kiosk,
        shiftRefresh,
    },
    state: {
        firm: { id: null, name: '' },
        getFirmsVersion: 1,
        developmentMode:
            location.hostname === 'localhost' &&
            sessionStorage.getItem('developmentMode') === 'yes',
    },
    mutations: {
        setFirm(state, firm) {
            state.firm = firm;
        },
        updateFirms(state) {
            state.getFirmsVersion++;
        },
        setDevelopmentMode(state, developmentMode) {
            state.developmentMode = !!developmentMode;
            sessionStorage.setItem(
                'developmentMode',
                developmentMode ? 'yes' : ''
            );
        },
    },
    getters: {
        firmInputType(state) {
            return { id: state.firm.id?.toString() };
        },
        getFirms(state) {
            state.getFirmsVersion;
            return () => m.$getFirms();
        },
    },
});
