import moment from 'moment';
import endpoints from '../apiEndPoints';
import getNewStartAndEnd from '../helpers/getNewStartAndEnd';
export default {
    namespaced: true,
    actions: {
        updateShiftFromDragAndDrop(
            { dispatch },
            { dataTransfer, newEmployee, newDay }
        ) {
            const { newStart: start, newEnd: end } = getNewStartAndEnd(
                newDay,
                moment(dataTransfer.getData('start')),
                moment(dataTransfer.getData('end'))
            );
            const newShift = {
                employee: newEmployee,
                start,
                end,
            };
            const oldEmployeeId = dataTransfer.getData('employeeId');
            const oldShift = {
                id: dataTransfer.getData('shiftId'),
                employee: oldEmployeeId ? { id: oldEmployeeId } : undefined,
                start: moment(dataTransfer.getData('start')),
                end: moment(dataTransfer.getData('end')),
            };
            dispatch('updateShift', {
                newShift: newShift,
                oldShift: oldShift,
            });
        },
        updateShift({ commit }, { newShift, oldShift }) {
            if (shiftsAreEqual(newShift, oldShift)) return;

            endpoints
                .$updateShiftGraphql({
                    employeeId: newShift.employee?.id,
                    shiftId: oldShift.id,
                    newStart: newShift.start,
                    newEnd: newShift.end,
                })
                .then(() => updateShiftEndpoints(commit, newShift, oldShift));
        },
    },
};

function updateShiftEndpoints(commit, newShift, oldShift) {
    const newDate = getDate(newShift.start);
    const oldDate = getDate(oldShift.start);
    const dateHasChanged = newDate !== oldDate;

    const newEmployee = { employee: newShift.employee };
    const oldEmployee = { employee: oldShift.employee };
    const employeeHasChanged =
        newEmployee.employee?.id !== oldEmployee.employee?.id;

    const fromToNewDate = { fromDate: newDate, toDate: newDate };
    const fromToOldDate = { fromDate: oldDate, toDate: oldDate };

    const updates = [];

    if (dateHasChanged) {
        updates.push(
            ['scheduledHours', [fromToNewDate, fromToOldDate]],
            ['shiftCount', [fromToNewDate, fromToOldDate]],
            ['shiftCostForDay', [{ atDate: oldDate }]]
        );
    }

    if (employeeHasChanged) {
        const employees = [newEmployee, oldEmployee].filter(
            e => e.employee !== undefined
        );
        updates.push(['scheduledHours', employees], ['shiftCount', employees]);
    }

    updates.push([
        'shiftsForDay',
        [
            { ...newEmployee, atDate: newDate },
            { ...oldEmployee, atDate: oldDate },
        ],
    ]);

    updates.forEach(([endpointName, updates]) => {
        commit(`${endpointName}/update`, updates, { root: true });
    });
}

function getDate(datetime) {
    return moment(datetime).subtract(6, 'hours').format('YYYY-MM-DD');
}

function shiftsAreEqual(newShift, oldShift) {
    return (
        newShift.employee?.id == oldShift.employee?.id &&
        newShift.start.isSame(oldShift.start) &&
        newShift.end.isSame(oldShift.end)
    );
}
