import decode from './decode.js';
import encode from './encode.js';
import * as apiMethods from './apiCall.js';
import { store } from './store/store.js';
import {
    employeeClient,
    executeQuery,
    kioskClient,
    publicClient,
    userClient,
} from './apollo.js';

const graphqlQueries = (loaders => {
    const result = {};
    loaders.forEach(
        ([getClient, endpointName, queryLoader, mutationLoader]) => {
            [
                [queryLoader, 'query'],
                [mutationLoader, 'mutation'],
            ].map(([loader, type]) => {
                loader.keys().forEach(filename => {
                    const m = /([^/]+)\.graphql$/.exec(filename);
                    if (!m) {
                        throw new Error(
                            `Unexpected file imported: ${filename}`
                        );
                    }
                    const query = loader(filename);
                    if (!query.definitions[0].name) {
                        query.definitions[0].name = {
                            kind: 'Name',
                            value: m[1],
                        };
                    }
                    result[endpointName(m[1])] = vars => {
                        return graphql(getClient(), {
                            [type]: query,
                            variables: {
                                firm: { id: store.state.firm.id },
                                firmId: store.state.firm.id,
                                ...vars,
                            },
                        });
                    };
                });
            });
        }
    );
    return result;
})([
    [
        () => (store.state.kiosk.isKioskMode ? kioskClient : userClient),
        n => `$${n}`,
        require.context('./queries', false, /^.+\.graphql$/),
        require.context('./mutations', false, /^.+\.graphql$/),
    ],
    [
        () => employeeClient,
        n => `$employee${capitalize(n)}`,
        require.context('./queries/employee', false, /^.+\.graphql$/),
        require.context('./mutations/employee', false, /^.+\.graphql$/),
    ],
    [
        () => publicClient,
        n => `$public${capitalize(n)}`,
        require.context('./queries/public', false, /^.+\.graphql$/),
        require.context('./mutations/public', false, /^.+\.graphql$/),
    ],
]);

function capitalize(s) {
    return s.replace(/^./, c => c.toUpperCase());
}

function addFirm(o) {
    return { ...o, firm_id: store.state.firm.id };
}

function employeeUrl(u) {
    return url('employee/') + u;
}

function url(u) {
    return '/personeelsplanning/' + u;
}

function getApi(params) {
    return lazy(() => apiMethods.getApi(params));
}

function apiCall(params) {
    return lazy(() => apiMethods.apiCall(params));
}

function graphql(client, params) {
    return lazy(() => executeQuery(client, params));
}

function makeMethod(prepare) {
    return Object.assign((...args) => prepare(...args)(), { prepare });
}

function lazy(f) {
    let result;
    return (...args) => result || (result = f(...args));
}

function makeMethods(o) {
    return Object.fromEntries(
        Object.entries(o).map(([k, v]) => [k, makeMethod(v)])
    );
}

function postFirmCall(path, parameters) {
    return apiCall({
        method: 'POST',
        url: url(path),
        parameters: addFirm(parameters),
    });
}

function ping(url) {
    return lazy(async () => {
        const response = await fetch(process.env.VUE_APP_API_URL + url);
        return response.ok;
    });
}

export default makeMethods({
    ...graphqlQueries,
    $ping() {
        return ping(url('ping'));
    },
    $employeePing() {
        return ping(employeeUrl('ping'));
    },
    $getShowHolidayRequestCreator() {
        return getApi({
            url: employeeUrl('get_show_holiday_request_creator'),
            employee: true,
        });
    },
    $saveNmbrsData(p) {
        return apiCall({
            method: 'POST',
            url: url('save_nmbrs_data'),
            parameters: addFirm(p),
            encoder: encode.nmbrsData,
        });
    },
    $activateKioskMode(p) {
        return apiCall({
            method: 'POST',
            url: url('activate_kiosk_mode'),
            parameters: addFirm(p),
        });
    },
    $createHolidayRequestReview(p) {
        return apiCall({
            method: 'POST',
            url: url('create_holiday_request_review'),
            parameters: addFirm(p),
        });
    },
    $createOrUpdateComponent(p) {
        return apiCall({
            method: 'POST',
            url: url('create_or_update_component'),
            parameters: addFirm(p),
        });
    },
    $downloadJournalEntries(p) {
        return apiCall({
            method: 'POST',
            url: url('download_journal_entries'),
            parameters: addFirm(p),
        });
    },
    $downloadPayrollRegister(p) {
        return apiCall({
            method: 'POST',
            url: url('download_payroll_register'),
            parameters: addFirm(p),
        });
    },
    $downloadPayrollRegisterAllEmployees(p) {
        return apiCall({
            method: 'POST',
            url: url('download_payroll_register_all_employees'),
            parameters: addFirm(p),
        });
    },
    $downloadJournalEntriesCumulative(p) {
        return apiCall({
            method: 'POST',
            url: url('download_journal_entries_cumulative'),
            parameters: addFirm(p),
        });
    },
    $downloadJournalsXml(p) {
        return apiCall({
            method: 'POST',
            url: url('download_journals_xml'),
            parameters: addFirm(p),
        });
    },
    $downloadPayslip(parameters) {
        return apiCall({
            method: 'POST',
            url: employeeUrl('download_payslip'),
            employee: true,
            parameters,
        });
    },
    $getAbstractHourComponents() {
        return getApi({
            url: url('get_abstract_hour_components'),
            parameters: addFirm({}),
        });
    },
    $getAbstractWageComponents() {
        return getApi({
            url: url('get_abstract_wage_components'),
            parameters: addFirm({}),
        });
    },
    $downloadSepaTaxes(p) {
        return apiCall({
            method: 'POST',
            url: url('download_sepa_taxes'),
            parameters: addFirm(p),
        });
    },
    $downloadSepaSalaries(p) {
        return apiCall({
            method: 'POST',
            url: url('download_sepa_salaries'),
            parameters: addFirm(p),
        });
    },
    $getPeriodRuns(p) {
        return getApi({
            url: url('get_period_runs'),
            parameters: addFirm(p),
        });
    },
    $downloadPayslips(p) {
        return apiCall({
            method: 'POST',
            url: url('download_payslips'),
            parameters: addFirm(p),
        });
    },
    $downloadCumulativeWageTax(p) {
        return apiCall({
            method: 'POST',
            url: url('download_cumulative_wage_tax'),
            parameters: addFirm(p),
        });
    },
    $downloadCumulativeWageTaxPerPeriod(p) {
        return apiCall({
            method: 'POST',
            url: url('download_cumulative_wage_tax_per_period'),
            parameters: addFirm(p),
        });
    },
    $downloadSentWageTax(p) {
        return apiCall({
            method: 'POST',
            url: url('download_sent_wage_taxes'),
            parameters: addFirm(p),
        });
    },
    $downloadAnnualStatement(p) {
        return apiCall({
            method: 'POST',
            url: url('download_annual_statement'),
            parameters: addFirm(p),
        });
    },
    $getFirmHasNmbrsId() {
        return getApi({
            url: url('get_firm_has_nmbrs_id'),
            parameters: addFirm({}),
        });
    },
    $getTotalPaymentMethodDifferencePerMethod(p) {
        return getApi({
            url: url('get_total_payment_method_difference_per_method'),
            parameters: addFirm(p),
        });
    },
    $getAdvisedAttendances(p) {
        return getApi({
            url: url('get_advised_attendances'),
            parameters: addFirm(p),
            decoder: decode.attendances,
        });
    },
    $employeeUpdateAvailability(p) {
        return apiCall({
            method: 'POST',
            url: employeeUrl('update_availability'),
            employee: true,
            parameters: p,
        });
    },
    $updateAvailabilityComment(p) {
        return apiCall({
            method: 'POST',
            url: employeeUrl('update_availability_comment'),
            employee: true,
            parameters: p,
        });
    },
    $updateAvailability(p) {
        return postFirmCall('update_availability', p);
    },
    $createAvailabilities(p) {
        return apiCall({
            method: 'POST',
            url: employeeUrl('create_availabilities'),
            employee: true,
            parameters: p,
            encoder: encode.availabilities,
        });
    },
    $getFirmWorkReadyCheckIsDisabled(p) {
        return getApi({
            url: url('get_firm_work_ready_check_is_disabled'),
            parameters: addFirm(p),
        });
    },
    $getEmployeeAvailabilityRequests() {
        return getApi({
            url: employeeUrl('get_availability_requests'),
            employee: true,
            decoder: decode.employeeAvailabilityRequests,
        });
    },
    $createAvailabilityRequest(p) {
        return postFirmCall('create_availability_request', p);
    },
    $getAvailabilityRequests() {
        return getApi({
            url: url('get_availability_requests'),
            parameters: addFirm({}),
            decoder: decode.availabilityRequests,
        });
    },
    $getVaultBalance(p) {
        return getApi({
            url: url('get_vault_balance'),
            parameters: addFirm(p),
        });
    },
    $getTotalPaymentMethodCountDifference(p) {
        return getApi({
            url: url('get_total_payment_method_count_difference'),
            parameters: addFirm(p),
        });
    },
    $deleteWage(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_wage'),
            parameters: addFirm(p),
        });
    },
    $getTotalPersonnelProductivity(p) {
        return getApi({
            url: url('get_total_personnel_productivity'),
            parameters: addFirm(p),
        });
    },
    $getTotalCostAndHoursPerEmployee(p) {
        return getApi({
            url: url('get_total_cost_and_hours_per_employee'),
            parameters: addFirm(p),
            decoder: decode.totalCostAndHoursPerEmployee,
        });
    },
    $getTotalCostAndHoursPerStation(p) {
        return getApi({
            url: url('get_total_cost_and_hours_per_station'),
            parameters: addFirm(p),
            decoder: decode.totalCostAndHoursPerStation,
        });
    },
    $getTotalRevenuePerCategory(p) {
        return getApi({
            url: url('get_total_revenue_per_category'),
            parameters: addFirm(p),
        });
    },
    $getTotalPersonnelHours(p) {
        return getApi({
            url: url('get_total_personnel_hours'),
            parameters: addFirm(p),
            decoder: decode.totalPersonnelHours,
        });
    },
    $getTotalPersonnelCost(p) {
        return getApi({
            url: url('get_total_personnel_cost'),
            parameters: addFirm(p),
        });
    },
    $getTotalPercentagePersonnelCost(p) {
        return getApi({
            url: url('get_total_percentage_personnel_cost'),
            parameters: addFirm(p),
        });
    },
    $getTotalRevenue(p) {
        return getApi({
            url: url('get_total_revenue'),
            parameters: addFirm(p),
        });
    },
    $deleteHours(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_hours'),
            parameters: addFirm(p),
        });
    },
    $getContracts(p) {
        return getApi({
            url: url('get_contracts'),
            parameters: addFirm(p),
            decoder: decode.contracts,
        });
    },
    $downloadHoursProof(p) {
        return apiCall({
            method: 'POST',
            url: url('download_hours_proof'),
            parameters: addFirm(p),
        });
    },
    $createHours(p) {
        return apiCall({
            method: 'POST',
            url: url('create_hours'),
            parameters: addFirm(p),
            encoder: encode.createHours,
        });
    },
    $deleteWorkRelation(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_work_relation'),
            parameters: addFirm(p),
        });
    },
    $createWorkRelation(p) {
        return apiCall({
            method: 'POST',
            url: url('create_work_relation'),
            parameters: addFirm(p),
            encoder: encode.createWorkRelation,
        });
    },
    $downloadPaymentMethodCountProof(p) {
        return apiCall({
            method: 'POST',
            url: url('download_payment_method_count_proof'),
            parameters: addFirm(p),
        });
    },
    $getDefaultEmployees() {
        return getApi({
            url: url('get_default_employees'),
            parameters: addFirm({}),
        });
    },
    $getAttendances(p) {
        return getApi({
            url: url('get_attendances'),
            parameters: addFirm(p),
            decoder: decode.attendances,
        });
    },
    $resendOnboardingEmail(parameters) {
        return apiCall({
            method: 'POST',
            url: url('resend_onboarding_email'),
            parameters: addFirm(parameters),
        });
    },
    $downloadExistingContract(parameters) {
        return apiCall({
            method: 'POST',
            url: url('download_existing_contract'),
            parameters: addFirm(parameters),
        });
    },
    $downloadWageTax(p) {
        return apiCall({
            method: 'POST',
            url: url('download_wage_tax'),
            parameters: addFirm(p),
        });
    },
    $createWageTax(p) {
        return apiCall({
            method: 'POST',
            url: url('create_wage_tax'),
            parameters: addFirm(p),
            encoder: encode.wageTax,
        });
    },
    $getWageTaxes(p) {
        return getApi({
            url: url('get_wage_taxes'),
            parameters: addFirm(p),
            decoder: decode.wageTax,
        });
    },
    $getStartCash(p) {
        return getApi({
            url: url('get_start_cash'),
            parameters: addFirm(p),
        });
    },
    $updateContractTemplate(parameters) {
        return apiCall({
            method: 'POST',
            url: url('update_contract_template'),
            parameters: addFirm(parameters),
            encoder: encode.contractTemplate,
        });
    },
    $downloadContractTemplate(p) {
        return apiCall({
            method: 'POST',
            url: url('download_contract_template'),
            parameters: addFirm(p),
        });
    },
    $downloadContractTemplateTest(p) {
        return apiCall({
            method: 'POST',
            url: url('download_contract_template_test'),
            parameters: addFirm(p),
        });
    },
    $refreshFromPos(p) {
        return apiCall({
            method: 'POST',
            url: url('refresh_from_pos'),
            parameters: addFirm(p),
        });
    },
    $getPaymentMethodsActual(p) {
        return getApi({
            url: url('get_payment_methods_actual'),
            parameters: addFirm(p),
        });
    },
    $deleteContractTemplate(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_contract_template'),
            parameters: addFirm(p),
        });
    },
    $deleteContract(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_contract'),
            parameters: addFirm(p),
        });
    },
    $createCostFactor(p) {
        return apiCall({
            method: 'POST',
            url: url('create_cost_factor'),
            parameters: addFirm(p),
            encoder: encode.createCostFactor,
        });
    },
    $getEmployeeCostFactors(p) {
        return getApi({
            url: url('get_employee_cost_factors'),
            parameters: addFirm(p),
            decoder: decode.getEmployeeCostFactors,
        });
    },
    $getScheduleCost(p) {
        return getApi({
            url: url('get_schedule_cost'),
            parameters: addFirm(p),
            decoder: decode.scheduleCost,
        });
    },
    $getActualShifts(p) {
        return getApi({
            url: employeeUrl('get_actual_shifts'),
            employee: true,
            parameters: p,
            decoder: decode.shifts,
        });
    },
    $createUser(p) {
        return apiCall({
            method: 'POST',
            url: url('create_user'),
            parameters: p,
        });
    },
    $deleteUser(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_user'),
            parameters: addFirm(p),
        });
    },
    $deleteShiftType(p) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_shift_type'),
            parameters: addFirm(p),
        });
    },
    $getWorkedHours(p) {
        return getApi({
            url: '/personeelsplanning/get_worked_hours',
            parameters: addFirm(p),
        });
    },
    $savePreplannedShiftCopies(p) {
        return apiCall({
            method: 'POST',
            url: url('save_preplanned_shift_copies'),
            parameters: addFirm(p),
            encoder: encode.saveShiftCopies,
        });
    },
    $saveShiftCopies(p) {
        return apiCall({
            method: 'POST',
            url: url('save_shift_copies'),
            parameters: addFirm(p),
            encoder: encode.saveShiftCopies,
        });
    },
    $uploadSignedContract(parameters) {
        return apiCall({
            method: 'POST',
            url: url('upload_signed_contract'),
            parameters: addFirm(parameters),
            encoder: encode.signedContract,
        });
    },
    $updateFirm(parameters) {
        return apiCall({
            method: 'POST',
            url: url('update_firm'),
            parameters,
        });
    },
    $archiveEmployee(parameters) {
        return apiCall({
            method: 'POST',
            url: url('archive_employee'),
            parameters: addFirm(parameters),
        });
    },
    $getEmployeeCurrentlyPresent() {
        return getApi({
            url: employeeUrl('get_currently_present'),
            employee: true,
        });
    },
    $undeleteShift(parameters) {
        return apiCall({
            method: 'POST',
            url: url('undelete_shift'),
            parameters: addFirm(parameters),
        });
    },
    $getCertificateCoverage(parameters) {
        return getApi({
            url: url('get_certificate_coverage'),
            parameters: addFirm(parameters),
            decoder: decode.certificate_coverage,
        });
    },
    $resetPassword(parameters) {
        return apiCall({
            method: 'POST',
            url: url('reset_password'),
            parameters: parameters,
        });
    },
    $forgotPassword(parameters) {
        return apiCall({
            method: 'POST',
            url: url('forgot_password'),
            parameters: parameters,
        });
    },
    $getWarnings(parameters) {
        return getApi({
            url: url('get_warnings'),
            parameters: addFirm(parameters),
            decoder: decode.warnings,
        });
    },
    $makePrePlannedScheduleOfDayWeek(parameters) {
        return apiCall({
            method: 'POST',
            url: url('make_pre_planned_schedule_of_day_week'),
            parameters: addFirm(parameters),
            encoder: encode.createPrePlannedScheduleOfDayWeek,
        });
    },
    $createCertificate(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_certificate'),
            parameters: addFirm(parameters),
            encoder: encode.createCertificate,
        });
    },
    $copyShifts(parameters) {
        return apiCall({
            method: 'POST',
            url: url('copy_shifts'),
            parameters: addFirm(parameters),
            encoder: encode.copyShifts,
        });
    },
    $deleteShifts(parameters) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_shifts'),
            parameters: addFirm(parameters),
            encoder: encode.deleteShifts,
        });
    },
    $getEmployeeSalaryFromSalaryTable(parameters) {
        return getApi({
            url: url('get_employee_salary_from_salary_table'),
            parameters: addFirm(parameters),
        });
    },
    $logout() {
        return apiCall({
            method: 'POST',
            url: url('logout'),
        });
    },
    $getEmployeeInfo(parameters) {
        return getApi({
            url: url('get_employee_info'),
            parameters: parameters,
            decoder: decode.employeeInfo,
        });
    },
    $uploadContractTemplate(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_new_contract_template'),
            parameters: parameters,
            encoder: encode.contractTemplate,
        });
    },
    $getFirms() {
        return getApi({
            url: url('get_firms'),
            decoder: decode.firms,
        });
    },
    $getContractTemplateTags() {
        return getApi({
            url: url('get_contract_template_tags'),
        });
    },
    $getRevenueCategories(parameters) {
        return getApi({
            url: url('get_revenue_categories'),
            parameters: parameters,
        });
    },
    $createNewEndOfDay(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_new_end_of_day'),
            parameters: addFirm(parameters),
            encoder: encode.endOfDay,
        });
    },
    $getPaymentMethods(parameters) {
        return getApi({
            url: url('get_payment_methods'),
            parameters: parameters,
        });
    },
    $deletePrePlannedSchedule(parameters) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_pre_planned_schedule'),
            parameters: addFirm(parameters),
        });
    },
    $importPrePlannedSchedule(parameters) {
        return apiCall({
            method: 'POST',
            url: url('import_pre_planned_schedule'),
            parameters: addFirm(parameters),
        });
    },
    $createNewPrePlannedSchedule(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_new_pre_planned_schedule'),
            parameters: addFirm(parameters),
        });
    },
    $getConflicts(parameters) {
        return getApi({
            url: url('get_conflicts'),
            parameters: addFirm(parameters),
            decoder: decode.conflicts,
        });
    },
    $deletePrePlannedShift(parameters) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_pre_planned_shift'),
            parameters: addFirm(parameters),
        });
    },
    $updatePrePlannedShift(parameters) {
        return apiCall({
            method: 'POST',
            encoder: encode.updatePrePlannedShift,
            url: url('update_pre_planned_shift'),
            parameters: addFirm(parameters),
        });
    },
    $createNewPrePlannedShift(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_new_pre_planned_shift'),
            parameters: addFirm(parameters),
            encoder: encode.newPrePlannedShift,
        });
    },
    $saveEmployee(parameters) {
        /*
            Takes an object that represents an employee.
            It should at least contain the id of the employee.
            All other fields are optional. All fields that are
            given to the backend will overwrite the old values.
            */
        return apiCall({
            method: 'POST',
            url: url('save_employee'),
            parameters: addFirm(parameters),
            encoder: encode.employee,
        });
    },
    $verifySmsCode(parameters) {
        return apiCall({
            method: 'POST',
            url: url('verify_sms_code'),
            parameters: parameters,
        });
    },
    $changeFirm(parameters) {
        return apiCall({
            method: 'POST',
            url: employeeUrl('change_firm'),
            parameters: parameters,
            employee: true,
        });
    },
    $saveEmployeeOnboarding(parameters) {
        return apiCall({
            method: 'POST',
            url: url('save_employee_onboarding'),
            parameters: parameters,
            encoder: encode.saveEmployeeOnboarding,
        });
    },
    $employeeLogout() {
        return apiCall({
            method: 'POST',
            url: employeeUrl('logout'),
            employee: true,
        });
    },
    $createNewShift(parameters) {
        return apiCall({
            method: 'POST',
            url: url('create_new_shift'),
            encoder: encode.shift,
            parameters: addFirm(parameters),
        });
    },
    $getSchedule(parameters) {
        return apiCall({
            method: 'POST',
            url: url('get_schedule'),
            json: true,
            decoder: decode.schedule,
            parameters: addFirm(parameters),
        });
    },
    $publishSchedule(parameters) {
        return apiCall({
            method: 'POST',
            url: url('publish_schedule'),
            encoder: encode.publish,
            parameters: addFirm(parameters),
        });
    },
    $deleteShift(parameters) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_shift'),
            parameters: addFirm(parameters),
        });
    },
    $deletePreplannedShift(parameters) {
        return apiCall({
            method: 'DELETE',
            url: url('delete_preplanned_shift'),
            parameters: addFirm(parameters),
        });
    },
    $updateShift(parameters) {
        return apiCall({
            method: 'POST',
            url: url('update_shift'),
            parameters: addFirm(parameters),
            encoder: encode.updateShift,
        });
    },
    $impersonateEmployee(parameters) {
        return apiCall({
            method: 'POST',
            url: url('impersonate_employee'),
            parameters: parameters,
        });
    },
});
